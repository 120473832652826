import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import SEO from '~/components/SEO'
import { PostByUrlPathQuery, IGasbyPageProps, PostDataFragmentFragment } from '~/types'
import PostView from '~/components/PostView'
import { formatPostFromData } from '~/utils/post'

interface IPostViewTemplateProps extends IGasbyPageProps {
  data: PostByUrlPathQuery
}

const PostViewTemplate: React.FC<IPostViewTemplateProps> = ({ data, uri, pageContext }) => {
  const post = formatPostFromData(data.markdownRemark as PostDataFragmentFragment)

  const meta = [
    {
      property: `og:url`,
      content: `${data.site.siteMetadata.siteUrl}${post.urlPath}`,
    },
  ]

  if (post.cover) {
    meta.push({
      property: `og:image`,
      content: post.cover.src,
    })
    post.coverSize.height
  }

  if (post.coverSize && post.coverSize.width) {
    meta.push({
      property: `og:image:width`,
      content: post.coverSize.width.toString(),
    })
  }

  if (post.coverSize && post.coverSize.height) {
    meta.push({
      property: `og:image:height`,
      content: post.coverSize.height.toString(),
    })
  }

  // if (post.audio) {
  //   meta.push({
  //     property: `og:audio`,
  //     content: post.audio.url,
  //   })
  // }

  if (post.youtube) {
    const id = post.youtube.match(/\/([^/]+?)$/)[1]
    meta.push({
      property: `og:image`,
      content: `https://img.youtube.com/vi/${id}/maxresdefault.jpg`,
    })
  }

  return (
    <Layout uri={uri}>
      <PostView post={post}></PostView>
      <SEO
        title={post.title}
        description={post.desc}
        canonical={post.urlPath}
        meta={meta}
      ></SEO>
    </Layout>
  )
}
export default PostViewTemplate

export const query = graphql`
  query postByURLPath($urlPath: String!, $contentWidth: Int!) {
    markdownRemark(fields: { urlPath: { eq: $urlPath } }) {
      ...PostDataFragment
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`