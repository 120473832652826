import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { IPost } from '~/types'
import styled from 'styled-components'
import ArticleContainer from './ArticleContainer'
import AudioView from '~/components/media-views/AudioView'
import YoutubeView from '~/components/media-views/YoutubeView'
import VkView from '~/components/media-views/VkView'
import { contentWidth, height16x9, linesRem } from '~/utils/styling'
// import { useAppContext } from '~/contexts/appContext'
import { GatsbyImageSharpFluidFragment } from '~/types'
import Tag from '~/components/Tag'
import SharePanel from '~/components/SharePanel'
import RutubeView from './media-views/RutubeView'
import Carousel from 'react-images'
import bgdark from '~/assets/bg-dark-3@2x.png'

interface IPostViewProps {
  post: IPost
}

const TagList = styled.div`
  display: flex;
`

const ConverContainer = styled.div`
  max-width: ${contentWidth}px;
  margin: 0 auto;
  @media (min-width: 560px) {
    margin-top: ${linesRem(1)};
  }
`

const CarouselWrapper = styled.div`
  width: 100%;
  background-image: url(${bgdark});
  background-size: 160px 160px;
  overflow: hidden;
  border-bottom: 1px solid white;
`

const View: React.FC<{ data: GatsbyImageSharpFluidFragment }> = ({ data }) => (
  <Img
    fluid={data}
    style={{
      maxWidth: `1120px`,
      maxHeight: `640px`,
      margin: `auto`,
    }}
    //@ts-ignore
    objectFit="cover"
    objectPosition="50% 50%"
  ></Img>
)


const PostView: React.FC<IPostViewProps> = ({ post }) => {

  let cover = null
  let video = null
  let audio = null
  
  if (post.type === 'video' && post.youtube) {
    video = <YoutubeView link={post.youtube} />
  }

  if (post.type === 'video' && post.vk) {
    video = <VkView link={post.vk} />
  }

  if (post.type === 'video' && post.rutube) {
    video = <RutubeView link={post.rutube} timeOffset={post.timeOffset} />
  }
  
  if (post.type === 'audio' || post.type === 'video') {
    audio = <AudioView
      audio={post.audio}
      audioList={post.audioList}
      audioUrl={post.audioUrl}
    />
  }
  
  if (post.cover) {
    if (post.images) {
      cover = (
          <CarouselWrapper>
            <Carousel views={post.images} components={{ Footer: null, View }}/>
          </CarouselWrapper>
      )
    } else {
      cover = <Img fluid={post.cover as GatsbyImageSharpFluidFragment} />
    }
    
    
  }

  let html = post.html
  if (/<!--\s*hide\s*-->/i.test(post.html)) {
    const parts1 = post.html.split(/<!--\s*hide\s*-->/i)
    html = parts1[0]

    if (/<!--\s*hide_end\s*-->/i.test(parts1[1])) {
      const parts2 = parts1[1].split(/<!--\s*hide_end\s*-->/i)
      html += '\n' + parts2[1]
    }
  }

  return (
    <>
      <ConverContainer>{cover}</ConverContainer>
      <ArticleContainer>
        <header className="fixed-width">
          {post.tags && post.tags.length > 0 && <TagList>
            {post.tags.map((label, index) => <Tag label={label} key={index}></Tag>)}
          </TagList>}
          <h2>{post.title}</h2>
          <h6>
            <div style={{ display: `flex`, flexDirection: `row`, alignItems: `stratch` }}>
              <span>{post.date}</span>
              <span style={{ flex: `1`}}></span>
              <SharePanel link={post.urlPath}></SharePanel>
            </div>
          </h6>
        </header>
        <main className="fixed-width">
          {video}
          {audio}
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </main>
      </ArticleContainer>
    </>
  )
}

export default PostView